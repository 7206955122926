
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppAdministratorResourcesDietsListIndex} from "@/models/app/administrator/resources/diets/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorResourcesDietsListFormCreateIndexVue from "@/views/app/administrator/resources/diets/list/form/create.vue";
import AppAdministratorResourcesDietsListFormUpdateIndexVue from "@/views/app/administrator/resources/diets/list/form/update.vue";
import AppAdministratorResourcesDietsListFormRemoveIndexVue from "@/views/app/administrator/resources/diets/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppAdministratorResourcesDietsListFormCreateIndexVue,
    AppAdministratorResourcesDietsListFormUpdateIndexVue,
    AppAdministratorResourcesDietsListFormRemoveIndexVue,
  },
})
export default class AppAdministratorResourcesDietsListIndexVue extends Vue {
  isLoading: boolean | null = false;

  diets: Array<AppAdministratorResourcesDietsListIndex> = [];

  async getDiets(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/diets`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.diets = r.data.sort(function (a, b) {
          if (a.content.name < b.content.name) {
            return -1;
          }
          if (a.content.name > b.content.name) {
            return 1;
          }
          return 0;
        });
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getDiets();
  }
}


// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorResourcesDietsListFormUpdate} from "@/models/app/administrator/resources/diets/list/form/update";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElInput,
  ElButton,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "diet",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    Edit,
  },
})
export default class AppAdministratorResourcesDietsListFormUpdateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  diet: AppAdministratorResourcesDietsListFormUpdate = new AppAdministratorResourcesDietsListFormUpdate();

  formData: AppAdministratorResourcesDietsListFormUpdate = new AppAdministratorResourcesDietsListFormUpdate();
  formDataRules = {
    content: {
      name: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
      description: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/administration/diet/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getDiets');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.diet;
  }

  resetFormData(): void {
    this.formData = new AppAdministratorResourcesDietsListFormUpdate();
  }

  updated(): void {
    this.setFormData();
  }
}
